import { useState } from 'react'
import { api } from '../lib/api'
import { useAuth } from '../lib/AuthContext'
import { parseJwt } from '../lib/utils'
import { Link } from 'react-router-dom'

export function LoginBox() {
  const { login, username, logout, isSuperUser } = useAuth()
  const [isLoginMode, setIsLoginMode] = useState(true)
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  })
  const [error, setError] = useState<string | null>(null)

  // If user is logged in, show profile section instead
  if (username) {
    return (
      <div className="rounded border border-red-800 bg-gray-900 p-4">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold text-red-500">Profile</h2>
            <p className="text-sm text-gray-400">
              Logged in as {username}
              {isSuperUser && (
                <Link to="/admin">
                  <span className="ml-2 cursor-pointer text-yellow-500 hover:text-yellow-400">
                    [super]
                  </span>
                </Link>
              )}
            </p>
          </div>
          <button
            onClick={logout}
            className="rounded bg-red-800 px-3 py-1 text-sm text-white hover:bg-red-700"
          >
            Logout
          </button>
        </div>
      </div>
    )
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)

    try {
      const response = await (isLoginMode ? api.login : api.signup)(formData)
      const { token } = response
      
      // Parse username from JWT
      const payload = parseJwt(token)
      const username = payload.sub as string
      
      login(token, username)
    } catch (err) {
      setError(isLoginMode ? 'Login failed' : 'Signup failed')
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div className="rounded border border-red-800 bg-gray-900 p-4">
      <h2 className="mb-3 text-lg font-semibold text-red-500">
        {isLoginMode ? 'Login' : 'Sign Up'}
      </h2>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        {!isLoginMode && (
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full rounded bg-gray-800 px-3 py-2 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
            />
          </div>
        )}
        
        <div>
          <input
            type="text"
            name="username"
            placeholder={isLoginMode ? "Username or Email" : "Username"}
            value={formData.username}
            onChange={handleInputChange}
            className="w-full rounded bg-gray-800 px-3 py-2 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
          />
        </div>
        
        <div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            className="w-full rounded bg-gray-800 px-3 py-2 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
          />
        </div>

        {error && (
          <div className="text-sm text-red-500">
            {error}
          </div>
        )}
        
        <button
          type="submit"
          className="w-full rounded bg-red-800 py-2 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-900"
        >
          {isLoginMode ? 'Login' : 'Sign Up'}
        </button>
        
        <div className="text-center text-sm">
          <button
            type="button"
            onClick={() => {
              setIsLoginMode(!isLoginMode);
              setFormData({ username: '', email: '', password: '' }); // Reset form when switching modes
              setError(null);
            }}
            className="text-gray-400 hover:text-white"
          >
            {isLoginMode ? 'Need an account? Sign up' : 'Already have an account? Login'}
          </button>
        </div>
      </form>
    </div>
  )
} 