import { useState, useEffect } from 'react'
import { api, Post, Comment } from '../lib/api'
import { useAuth } from '../lib/AuthContext'
import { ArrowUp, X, MessageSquare, Share2 } from 'lucide-react'
import { useParams, useNavigate } from 'react-router-dom'
import { MainLayout } from './MainLayout'

export function PostDetail() {
  const params = useParams()
  const { hashedId } = params
  console.log('Route params:', params)  // Debug log
  console.log('hashedId:', hashedId)    // Debug log
  
  const navigate = useNavigate()
  const { token, username: currentUsername } = useAuth()
  const [post, setPost] = useState<Post | null>(null)
  const [content, setContent] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [comments, setComments] = useState<Comment[]>([])
  const [loading, setLoading] = useState(true)
  const [replyTo, setReplyTo] = useState<string | null>(null)
  const [showShareTooltip, setShowShareTooltip] = useState(false)
  const [userVote, setUserVote] = useState<number>(0)

  // Fetch post data
  useEffect(() => {
    const fetchPost = async () => {
      if (!hashedId) return
      try {
        console.log('Fetching post with hashedId:', hashedId)
        const postData = await api.getPost(hashedId)
        console.log('Received post data:', postData)
        setPost(postData.post)
        setComments(postData.comments)
        // Load saved vote if any
        const savedVotes = localStorage.getItem('userVotes')
        if (savedVotes) {
          const votes = JSON.parse(savedVotes)
          setUserVote(votes[postData.post.id] || 0)
        }
        setLoading(false)
      } catch (err) {
        console.error('Error fetching post:', err)
        setError('Failed to load post')
      }
      
    }
    fetchPost()
  }, [hashedId])

  const handleClose = () => {
    navigate('/') // Go back to home page
  }

  const handleVote = async (newValue: number) => {
    if (!token || !post) {
      setError('Please login to vote')
      return
    }

    try {
      const value = userVote === newValue ? 0 : newValue
      await api.vote(post.id, value, token)
      setUserVote(value)
      // Update post vote count
      setPost(prev => prev ? { ...prev, voteCount: prev.voteCount + (value - userVote) } : null)
      setError(null)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const fetchComments = async () => {
    if (!post) return
    try {
      const data = await api.getComments(post.id)
      setComments(data)
    } catch (err) {
      setError('Failed to load comments')
    } finally {
      setLoading(false)
    }
  }

  // Add effect for fetching comments when post changes


  const handleSubmit = async (e: React.FormEvent, parentId: string | null = null) => {
    e.preventDefault()
    if (!token || !content.trim() || !post) return

    setSubmitting(true)
    setError(null)
    try {
      await api.createComment({
        postId: post.id,
        parentId: parentId || undefined,
        content: content.trim(),
      }, token)
      setContent('')
      setReplyTo(null)
      await fetchComments()
    } catch (err) {
      setError('Failed to post comment')
    } finally {
      setSubmitting(false)
    }
  }

  const handleShare = async () => {
    if (!post) return
    const url = `${window.location.origin}/post/${post.hashedId}`
    
    try {
      await navigator.clipboard.writeText(url)
      setShowShareTooltip(true)
      setTimeout(() => setShowShareTooltip(false), 2000)
    } catch (err) {
      console.error('Failed to copy URL:', err)
    }
  }

  const renderComment = (comment: Comment, depth = 0) => {
    const replies = comments.filter(c => c.parentId === comment.id)
    const isReplyOpen = replyTo === comment.id

    return (
      
      <div 
        key={comment.id} 
        className={`border-l border-red-800 pl-4 ${depth > 0 ? 'mt-2' : 'mt-4'}`}
      >
        <div className="flex items-start gap-3">
          {/* Upvote button */}
          

          {/* Comment content */}
          <div className="flex-1">
            <div className="text-gray-300">{comment.content}</div>
            <div className="mt-1 flex items-center gap-3 text-xs text-gray-500">
              <span>{comment.username.username}</span>
              <span>{new Date(comment.createdAt).toLocaleDateString()}</span>
              {currentUsername && !isReplyOpen && (
                <button
                  onClick={() => setReplyTo(comment.id)}
                  className="flex items-center gap-1 text-gray-400 hover:text-white"
                >
                  <MessageSquare className="h-3 w-3" />
                  Reply
                </button>
              )}
            </div>

            {/* Inline reply form */}
            {isReplyOpen && (
              <form onSubmit={(e) => handleSubmit(e, comment.id)} className="mt-2">
                <div className="flex items-start gap-2">
                  <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Write a reply..."
                    className="flex-1 rounded bg-gray-800 px-3 py-2 text-sm text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
                    rows={2}
                  />
                  <div className="flex flex-col gap-2">
                    <button
                      type="submit"
                      disabled={submitting || !content.trim()}
                      className="rounded bg-red-800 px-3 py-1 text-sm text-white hover:bg-red-700 disabled:opacity-50"
                    >
                      {submitting ? '...' : 'Reply'}
                    </button>
                    <button
                      type="button"
                      onClick={() => setReplyTo(null)}
                      className="rounded px-3 py-1 text-sm text-gray-400 hover:text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}

            {/* Nested replies */}
            {replies.length > 0 && (
              <div className="space-y-2">
                {replies.map(reply => renderComment(reply, depth + 1))}
              </div>
            )}
          </div>
        </div>
      </div>
      
    )
  }

  if (!post) {
    return (
      <MainLayout>
      <div className="container mx-auto p-4 text-white">
        {error ? (
          <div className="text-red-500">{error}</div>
        ) : (
          <div>Loading post...</div>
        )}
      </div>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
    <div className="mx-auto max-w-3xl space-y-6 bg-black">
      <div className="relative">
        <div className="absolute right-4 top-4 flex items-center gap-4">
          <div className="relative">
            <button
              onClick={handleShare}
              className="text-gray-400 hover:text-white"
              title="Share this post"
            >
              <Share2 className="h-5 w-5" />
            </button>
            {showShareTooltip && (
              <div className="absolute right-0 top-full mt-2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-sm text-white">
                Link copied to clipboard!
              </div>
            )}
          </div>
          <button
            onClick={handleClose}
            className="text-gray-400 hover:text-white"
            aria-label="Close"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Post */}
        <div className="border border-red-800 bg-gray-900 p-6">
          <div className="flex items-start gap-4">
            {/* Upvote button */}
            <div className="flex flex-col items-center">
              <button
                onClick={() => handleVote(1)}
                className={`group flex flex-col items-center ${
                  userVote === 1 ? 'text-red-500' : 'text-gray-500'
                }`}
                title={token ? 'Vote up' : 'Login to vote'}
              >
                <ArrowUp 
                  className={`h-5 w-5 transition-colors ${
                    userVote === 1 
                      ? 'text-red-500' 
                      : 'text-gray-500 group-hover:text-red-500'
                  }`}
                />
              </button>
              <span className="text-sm font-medium text-gray-300">
                {post.voteCount}
              </span>
              <button
                onClick={() => handleVote(-1)}
                className={`group flex flex-col items-center rotate-180 ${
                  userVote === -1 ? 'text-blue-500' : 'text-gray-500'
                }`}
                title={token ? 'Vote down' : 'Login to vote'}
              >
                <ArrowUp 
                  className={`h-5 w-5 transition-colors ${
                    userVote === -1 
                      ? 'text-blue-500' 
                      : 'text-gray-500 group-hover:text-blue-500'
                  }`}
                />
              </button>
            </div>

            {/* Post content */}
            <div className="flex-1">
              <h2 className="text-2xl">
                <a 
                  href={post.link} 
                  className="text-red-500 hover:underline" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {post.description}
                </a>
              </h2>
              
              <p className="mt-2 text-gray-400">
                {post.shortDescription}
              </p>
              
              <div className="mt-3 text-sm text-gray-500">
                Posted by {post.username} on {new Date(post.createdAt).toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>

        {/* Comments section */}
        <div className="mt-8">
          <h3 className="mb-6 text-lg font-semibold text-red-500">Comments</h3>

          {error && (
            <div className="mb-4 text-sm text-red-500">
              {error}
            </div>
          )}

          {/* Comments list */}
          <div className="space-y-6">
            {loading && comments.length === 0 ? (
              <div className="text-gray-400">Loading comments...</div>
            ) : comments.length > 0 ? (
              comments
                .filter(comment => !comment.parentId)
                .map(comment => renderComment(comment))
            ) : (
              <div className="text-gray-400">No comments yet</div>
            )}
          </div>
        </div>

        {/* Main comment form */}
        {currentUsername && !replyTo && (
          <div className="border-t border-red-800 bg-gray-900 p-4">
            <div className="container mx-auto">
              <form onSubmit={(e) => handleSubmit(e)} className="flex gap-3">
                <textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Write a comment..."
                  className="flex-1 rounded bg-gray-800 px-3 py-2 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
                  rows={2}
                />
                <button
                  type="submit"
                  disabled={submitting || !content.trim()}
                  className="rounded bg-red-800 px-4 py-2 text-white hover:bg-red-700 disabled:opacity-50"
                >
                  {submitting ? 'Posting...' : 'Post Comment'}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div></MainLayout>
  )
} 