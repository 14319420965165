import { useEffect, useState } from 'react'
import { api, Post } from '../lib/api'
import { ArrowUp, ArrowDown, Plus, MessageSquare } from 'lucide-react'
import { useAuth } from '../lib/AuthContext'
import { PostForm } from './PostForm'
import { PostDetail } from './PostDetail'
import { MainLayout } from './MainLayout'

interface UserVotes {
  [id: string]: number;  // -1, 0, or 1
}

export function PostList() {
  const [posts, setPosts] = useState<Post[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [upvoteError, setUpvoteError] = useState<string | null>(null)
  const [showPostForm, setShowPostForm] = useState(false)
  const [selectedPost, setSelectedPost] = useState<Post | null>(null)
  const [userVotes, setUserVotes] = useState<UserVotes>({})

  const { token, username } = useAuth()

  const fetchPosts = async () => {
    try {
      const { posts: fetchedPosts, userVotes: fetchedVotes } = await api.getPosts()
      setPosts(fetchedPosts)
      setUserVotes(fetchedVotes)
      
      // Check if there's a post ID in the URL
      const hash = window.location.hash.slice(1)
      if (hash) {
        const post = fetchedPosts.find(p => p.hashedId === hash)
        if (post) {
          setSelectedPost(post)
        }
      }
    } catch (err) {
      console.error(err)
      setError('Failed to load posts')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  useEffect(() => {
    // Listen for hash changes
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1)
      if (!hash) {
        setSelectedPost(null)
        return
      }
      const post = posts.find(p => p.hashedId === hash)
      if (post) {
        setSelectedPost(post)
      }
    }

    window.addEventListener('hashchange', handleHashChange)
    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [posts])

  const handleSelectPost = (post: Post) => {
    setSelectedPost(post)
    window.location.hash = post.hashedId
  }

  const handleClosePost = () => {
    setSelectedPost(null)
    window.location.hash = ''
  }

  const handleVote = async (postId: string, value: number) => {
    if (!token) {
      setUpvoteError('Please login to vote')
      return
    }

    try {
      const updatedPost = await api.votePost(postId, value, token)
      
      // Update local vote state
      setUserVotes(prev => ({
        ...prev,
        [postId]: value
      }))

      // Update posts with new vote count
      setPosts(posts.map(post => 
        post.id === postId ? updatedPost : post
      ))
      setUpvoteError(null)
    } catch (err) {
      if (err instanceof Error) {
        setUpvoteError(err.message)
      }
    }
  }

  if (loading) return <div className="text-gray-400">Loading...</div>
  if (error) return <div className="text-red-500">{error}</div>

  return (
    <MainLayout>
      {selectedPost ? (
        <PostDetail
          post={selectedPost}
          onClose={handleClosePost}
          onVote={handleVote}
        />
      ) : (
        <div className="space-y-4">
          {username && (
            <div className="mb-6 flex justify-end">
              <button
                onClick={() => setShowPostForm(true)}
                className="flex items-center gap-2 rounded bg-red-800 px-4 py-2 text-white hover:bg-red-700"
              >
                <Plus className="h-4 w-4" />
                Create Post
              </button>
            </div>
          )}

          {showPostForm && (
            <PostForm
              onClose={() => setShowPostForm(false)}
              onPostCreated={fetchPosts}
            />
          )}

          {upvoteError && (
            <div className="mb-4 text-sm text-red-500">
              {upvoteError}
            </div>
          )}
          
          {posts.map((post) => (
            <div key={post.id} className="border border-red-800 bg-gray-900 p-4">
              <div className="flex items-start gap-4">
                {/* Vote buttons */}
                <div className="flex flex-col items-center">
                  <button
                    onClick={() => handleVote(post.id, userVotes[post.id] === 1 ? 0 : 1)}
                    className={`group flex items-center ${
                      userVotes[post.id] === 1 ? 'text-red-500' : 'text-gray-500'
                    }`}
                    title={token ? 'Vote up' : 'Login to vote'}
                  >
                    <ArrowUp className={`h-5 w-5 transition-colors ${
                      userVotes[post.id] === 1
                        ? 'text-red-500'
                        : 'text-gray-500 group-hover:text-red-500'
                    }`} />
                  </button>
                  
                  {/* Vote count */}
                  <span className="my-1 text-sm font-medium text-gray-300">
                    {post.voteCount}
                  </span>
                  
                  {/* Downvote button */}
                  <button
                    onClick={() => handleVote(post.id, userVotes[post.id] === -1 ? 0 : -1)}
                    className={`group flex items-center ${
                      userVotes[post.id] === -1 ? 'text-blue-500' : 'text-gray-500'
                    }`}
                    title={token ? 'Vote down' : 'Login to vote'}
                  >
                    <ArrowDown className={`h-5 w-5 transition-colors ${
                      userVotes[post.id] === -1
                        ? 'text-blue-500'
                        : 'text-gray-500 group-hover:text-blue-500'
                    }`} />
                  </button>
                </div>

                {/* Post content */}
                <div className="flex-1">
                  <h2 className="text-lg">
                    <a 
                      href={post.link} 
                      className="text-red-500 hover:underline" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {post.description}
                    </a>
                  </h2>
                  
                  <p className="mt-1 text-sm text-gray-400">
                    {post.shortDescription}
                  </p>
                  
                  <div className="mt-2 text-xs text-gray-500">
                    Posted by {post.username} on {new Date(post.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </div>
              
              <div className="mt-4 flex items-center gap-4 border-t border-red-800/30 pt-4">
                <button
                  onClick={() => handleSelectPost(post)}
                  className="flex items-center gap-2 text-sm text-gray-400 hover:text-white"
                >
                  <MessageSquare className="h-4 w-4" />
                  Comments
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </MainLayout>
  )
} 