import { ReactNode } from 'react'
import { LoginBox } from './LoginBox'

interface MainLayoutProps {
  children: ReactNode
  onHomeClick?: () => void
}

export function MainLayout({ children, onHomeClick }: MainLayoutProps) {
  return (
    <div className="min-h-screen bg-black text-white">
      <header className="border-b border-red-800 p-4">
        <div className="container mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={onHomeClick}
                className="text-2xl font-bold text-red-600 hover:text-red-500"
              >
                FD:HUMAN
              </button>
              <span className="ml-2 text-sm text-gray-400">jobs lost to AI</span>
            </div>
          </div>
        </div>
      </header>
      <div className="container mx-auto flex gap-6 p-4">
        <main className="flex-1">
          {children}
        </main>
        <aside className="w-80 space-y-6">
          {/* Content Box */}
          <div className="rounded border border-red-800 bg-gray-900 p-4">
            <h2 className="mb-3 text-lg font-semibold text-red-500">Latest Updates</h2>
            <div className="text-sm text-gray-300">
              <p>Track jobs being replaced by AI in real-time. Share your stories and connect with others affected by automation.</p>
            </div>
          </div>
          
          {/* Login Box */}
          <LoginBox />
        </aside>
      </div>
    </div>
  )
} 