import { useState, useRef } from 'react'
import { api } from '../lib/api'
import { useAuth } from '../lib/AuthContext'
import { X } from 'lucide-react'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'

interface PostFormProps {
  onClose: () => void
  onPostCreated: () => void
}

export function PostForm({ onClose, onPostCreated }: PostFormProps) {
  const { token } = useAuth()
  const [formData, setFormData] = useState({
    link: '',
    description: '',
    shortDescription: '',
  })
  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null)
  const turnstileRef = useRef<TurnstileInstance>(null)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    
    if (!token) {
      setError('You must be logged in to create a post')
      //return
    }

    if (!turnstileToken) {
      setError('Please complete the CAPTCHA')
      return
    }

    setError(null)
    setIsSubmitting(true)

    try {
      await api.createPost({ ...formData, turnstileToken }, token)
      onPostCreated()
      onClose()
    } catch (err) {
      setError('Failed to create post')
      turnstileRef.current?.reset()
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="w-full max-w-lg rounded border border-red-800 bg-gray-900 p-6">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-lg font-semibold text-red-500">Submit New Post</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white"
            aria-label="Close"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="mb-1 block text-sm text-gray-300">
              Link
            </label>
            <input
              type="url"
              name="link"
              required
              placeholder="https://example.com"
              value={formData.link}
              onChange={handleInputChange}
              className="w-full rounded bg-gray-800 px-3 py-2 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
            />
          </div>

          <div>
            <label className="mb-1 block text-sm text-gray-300">
              Title/Description
            </label>
            <input
              type="text"
              name="description"
              required
              placeholder="Main description of the job loss"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full rounded bg-gray-800 px-3 py-2 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
            />
          </div>

          <div>
            <label className="mb-1 block text-sm text-gray-300">
              Short Description
            </label>
            <textarea
              name="shortDescription"
              required
              placeholder="Brief summary of the impact"
              value={formData.shortDescription}
              onChange={handleInputChange}
              rows={3}
              className="w-full rounded bg-gray-800 px-3 py-2 text-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-red-500"
            />
          </div>

          <div className="my-4">
          <Turnstile siteKey='0x4AAAAAAA13W1_0K7BPwe4d'
          onSuccess={(token) => setTurnstileToken(token)}
          onError={() => setError('CAPTCHA verification failed')}
          onExpire={() => setTurnstileToken(null)}
          />
            
          </div>

          {error && (
            <div className="text-sm text-red-500">
              {error}
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="rounded px-4 py-2 text-gray-400 hover:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="rounded bg-red-800 px-4 py-2 text-white hover:bg-red-700 disabled:opacity-50"
            >
              {isSubmitting ? 'Submitting...' : 'Submit Post'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
} 