import { createContext, useContext, useState, ReactNode } from 'react'
import { parseJwt } from './utils'

interface AuthContextType {
  token: string | null
  username: string | null
  isSuperUser: boolean
  login: (token: string, username: string) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export function AuthProvider({ children }: { children: ReactNode }) {
  // Initialize state from localStorage
  const [username, setUsername] = useState<string | null>(() => {
    const storedUsername = localStorage.getItem('username')
    return storedUsername
  })

  const [isSuperUser, setIsSuperUser] = useState(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const payload = parseJwt(token)
      return payload.superUser || false
    }
    return false
  })

  const login = (token: string, username: string) => {
    localStorage.setItem('token', token)
    localStorage.setItem('username', username)
    setUsername(username)
    
    // Parse superUser status from token
    const payload = parseJwt(token)
    setIsSuperUser(payload.superUser || false)
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    setUsername(null)
    setIsSuperUser(false)
  }

  return (
    <AuthContext.Provider value={{ token: localStorage.getItem('token'), username, isSuperUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
} 