const API_BASE_URL = import.meta.env.VITE_API_URL || 'https://api.fuckedhuman.com'

interface LoginCredentials {
  username: string
  password: string
}

interface PostData {
  link: string
  description: string
  shortDescription: string
}
interface PostDetails {
  post: Post
  comments: Comment[]
}
interface username{
  username: string
  superuser: boolean
}
export interface Post {
  id: string
  hashedId: string
  description: string
  shortDescription: string
  link: string
  username: string
  createdAt: string
  voteCount: number
  userVote?: number
}

export interface Comment {
  id: string
  postId: string
  parentId: string | null
  content: string
  username: username
  createdAt: string
  score: number
}

const getToken = () => localStorage.getItem('token') || ''

export const api = {
  async login(credentials: LoginCredentials) {
    console.log(credentials);
    const response = await fetch(`${API_BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
    
    if (!response.ok) {
      
      throw new Error('Login failed')
    }
    
    return response.json()
  },

  async signup(credentials: LoginCredentials) {
    const response = await fetch(`${API_BASE_URL}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      
      body: JSON.stringify(credentials),
    })
    //console.log(await response.text());
    if (!response.ok) {
      throw new Error('Signup failed')
    }
    
    return response.json()
  },

  async getPosts() {
    const response = await fetch(`${API_BASE_URL}/posts`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
      }
    })
    if (!response.ok) {
      throw new Error('Failed to fetch posts')
    }
    return response.json()
  },

  async createPost(data: { 
    link: string
    description: string
    shortDescription: string
    turnstileToken: string
  }, token: string | null) {
    if (!token) {
      throw new Error('Authentication required')
    }

    const response = await fetch(`${API_BASE_URL}/post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
    
    if (!response.ok) {
      throw new Error('Failed to create post')
    }
    
    return response.json()
  },

  async votePost(postId: string, value: number, token: string): Promise<Post> {
    const response = await fetch(`${API_BASE_URL}/posts/${postId}/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ value }),
    });

    if (!response.ok) {
      const error = await response.text();
      console.error('Vote error:', error);
      throw new Error('Failed to vote');
    }

    return response.json();
  },

  async voteComment(commentId: string, isUpvote: boolean, token: string) {
    const endpoint = isUpvote ? 'upvote-comment' : 'downvote-comment';
    const response = await fetch(`${API_BASE_URL}/${endpoint}/${commentId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    
    if (!response.ok) {
      throw new Error('Failed to vote on comment')
    }
    
    return response.json()
  },

  async getComments(postId: string) {
    const response = await fetch(`${API_BASE_URL}/comments?postId=${postId}`)
    if (!response.ok) {
      throw new Error('Failed to fetch comments')
    }
    return response.json()
  },

  async createComment(data: { postId: string; parentId?: string; content: string }, token: string) {
    const response = await fetch(`${API_BASE_URL}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
    
    if (!response.ok) {
      throw new Error('Failed to create comment')
    }
    
    return response.json()
  },

  async removeUpvote(postId: string, token: string) {
    const response = await fetch(`${API_BASE_URL}/remove-upvote/${postId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    //console.log(await response.text());
    if (!response.ok) {
      if (response.status === 400) {
        throw new Error('Not upvoted')
      }
      throw new Error('Failed to remove upvote')
    }
    
    return response.json()
  },

  async removeCommentUpvote(commentId: string, token: string) {
    const response = await fetch(`${API_BASE_URL}/remove-comment-upvote/${commentId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    
    if (!response.ok) {
      if (response.status === 400) {
        throw new Error('Not upvoted')
      }
      throw new Error('Failed to remove comment upvote')
    }
    
    return response.json()
  },

  async removeDownvote(postId: string, token: string) {
    
    const response = await fetch(`${API_BASE_URL}/remove-downvote/${postId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    console.log(await response.text());
    if (!response.ok) {
      if (response.status === 400) {
        throw new Error('Not downvoted')
      }
      throw new Error('Failed to remove downvote')
    }
    
    return response.json()
  },

  async listUsers() {
    const response = await fetch(`${API_BASE_URL}/admin/users`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    if (!response.ok) throw new Error('Failed to list users')
    return await response.json()
  },

  async deleteUser(username: string) {
    const response = await fetch(`${API_BASE_URL}/admin/users/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({ usernameToDelete: username }),
    })
    if (!response.ok) throw new Error('Failed to delete user')
    return await response.json()
  },

  async getAllPosts(): Promise<{ posts: Post[] }> {
    const response = await fetch(`${API_BASE_URL}/admin/posts`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    if (!response.ok) throw new Error('Failed to fetch posts')
    return response.json()
  },

  async togglePostApproval(postId: string): Promise<Post> {
    const response = await fetch(`${API_BASE_URL}/admin/posts/toggle-approval`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({ postId }),
    })
    if (!response.ok) throw new Error('Failed to toggle post approval')
    return response.json()
  },

  getPost(hashedId: string): Promise<PostDetails> {
    return fetch(`${API_BASE_URL}/post/${hashedId}`, {
      headers: {
        'Authorization': `Bearer ${getToken()}`,
      },
    })
    .then(async response => {
      if (!response.ok) {
        const text = await response.text()
        console.error('Failed to fetch post:', text)
        throw new Error('Failed to fetch post')
      }
      //console.log(await response.json());
      return response.json()
    })
  },

  vote(postId: string, value: number, token: string): Promise<void> {
    return fetch(`${API_BASE_URL}/posts/${postId}/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ value }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to vote')
      }
    })
  },
} 