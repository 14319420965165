import { useState, useEffect } from 'react'
import { api } from '../lib/api'

interface User {
  username: string
  email: string
  createdAt: string
}

interface Post {
  id: string
  hashedId: string
  link: string
  description: string
  shortDescription: string
  username: string
  createdAt: string
  voteCount: number
  approved: boolean
}

export function AdminPanel() {
  const [activeTab, setActiveTab] = useState('users')
  const [users, setUsers] = useState<User[]>([])
  const [posts, setPosts] = useState<Post[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (activeTab === 'users') {
      loadUsers()
    } else if (activeTab === 'posts') {
      loadPosts()
    }
  }, [activeTab])

  const loadUsers = async () => {
    try {
      setLoading(true)
      setError(null)
      const response = await api.listUsers()
      setUsers(response.users)
    } catch (err) {
      setError('Failed to load users')
      console.error('Error loading users:', err)
    } finally {
      setLoading(false)
    }
  }

  const loadPosts = async () => {
    try {
      setLoading(true)
      setError(null)
      const response = await api.getAllPosts()
      setPosts(response.posts)
    } catch (err) {
      setError('Failed to load posts')
      console.error('Error loading posts:', err)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteUser = async (username: string) => {
    if (!confirm(`Are you sure you want to delete user "${username}"?`)) {
      return
    }

    try {
      setError(null)
      await api.deleteUser(username)
      await loadUsers()
    } catch (err) {
      setError('Failed to delete user')
      console.error('Error deleting user:', err)
    }
  }

  const handleToggleApproval = async (postId: string) => {
    try {
      setError(null)
      await api.togglePostApproval(postId)
      await loadPosts() // Refresh the list
    } catch (err) {
      setError('Failed to toggle post approval')
      console.error('Error toggling post approval:', err)
    }
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="mb-6 text-2xl font-bold text-red-500">Admin Panel</h1>
      
      {/* Tabs */}
      <div className="mb-6 border-b border-red-800">
        <button
          className={`mr-4 pb-2 ${
            activeTab === 'users'
              ? 'border-b-2 border-red-500 text-red-500'
              : 'text-gray-400 hover:text-red-500'
          }`}
          onClick={() => setActiveTab('users')}
        >
          User Management
        </button>
        <button
          className={`mr-4 pb-2 ${
            activeTab === 'posts'
              ? 'border-b-2 border-red-500 text-red-500'
              : 'text-gray-400 hover:text-red-500'
          }`}
          onClick={() => setActiveTab('posts')}
        >
          Post Management
        </button>
      </div>

      {/* Error Display */}
      {error && (
        <div className="mb-4 rounded bg-red-900/50 p-3 text-red-500">
          {error}
        </div>
      )}

      {/* Content */}
      {loading ? (
        <div className="text-gray-400">Loading...</div>
      ) : activeTab === 'users' ? (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-red-800 text-left text-sm text-gray-400">
                <th className="pb-2">Username</th>
                <th className="pb-2">Email</th>
                <th className="pb-2">Created At</th>
                <th className="pb-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr
                  key={user.username}
                  className="border-b border-gray-800 text-gray-300"
                >
                  <td className="py-2">{user.username}</td>
                  <td className="py-2">{user.email}</td>
                  <td className="py-2">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="py-2">
                    <button
                      onClick={() => handleDeleteUser(user.username)}
                      className="rounded bg-red-900 px-2 py-1 text-sm text-white hover:bg-red-800"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-red-800 text-left text-sm text-gray-400">
                <th className="pb-2">Title</th>
                <th className="pb-2">Author</th>
                <th className="pb-2">Created At</th>
                <th className="pb-2">Status</th>
                <th className="pb-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post) => (
                <tr
                  key={post.id}
                  className="border-b border-gray-800 text-gray-300"
                >
                  <td className="py-2">
                    <a 
                      href={post.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-red-500"
                    >
                      {post.shortDescription}
                    </a>
                  </td>
                  <td className="py-2">{post.username}</td>
                  <td className="py-2">
                    {new Date(post.createdAt).toLocaleDateString()}
                  </td>
                  <td className="py-2">
                    <span className={post.approved ? 'text-green-500' : 'text-yellow-500'}>
                      {post.approved ? 'Approved' : 'Pending'}
                    </span>
                  </td>
                  <td className="py-2">
                    <button
                      onClick={() => handleToggleApproval(post.id)}
                      className={`rounded px-2 py-1 text-sm text-white ${
                        post.approved 
                          ? 'bg-yellow-900 hover:bg-yellow-800' 
                          : 'bg-green-900 hover:bg-green-800'
                      }`}
                    >
                      {post.approved ? 'Unapprove' : 'Approve'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
} 