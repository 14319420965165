import { AuthProvider } from './lib/AuthContext'
import { PostList } from './components/PostList'
import { AdminPanel } from './components/AdminPanel'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { PostDetail } from './components/PostDetail'
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PostList />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/post/:hashedId" element={<PostDetail />} />
  
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
